













































































































































import {defineComponent, PropType} from '@vue/composition-api';
import STextarea from '@/common/components/STextarea.vue';
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import CalculationInput from '@/tasks/components/inputs/CalculationInput.vue';

export default defineComponent({
  name: 'uOttawa1321Lab3Discussionv2',
  components: {CalculationInput, STextarea},
  mixins: [DynamicQuestionMixin()],
  props: {
    isMarking: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
  },
  data() {
    return {
      inputs: {
        discussionA: null,
        discussionB: null,
        discussionC: null,
        unknownNumber: null,
        language: 'en',
      },

      rulesA: [(v: string) => v?.length <= 3000 || 'Max 3000 characters'],
      rulesB: [(v: string) => v?.length <= 3000 || 'Max 3000 characters'],
      rulesC: [(v: string) => v?.length <= 5000 || 'Max 5000 characters'],
      rulesAfr: [(v: string) => v?.length <= 3200 || 'Max 3200 charactères'],
      rulesBfr: [(v: string) => v?.length <= 3200 || 'Max 3200 charactères'],
      rulesCfr: [(v: string) => v?.length <= 5200 || 'Max 5200 charactères'],
      questions: [
        {
          en: 'Explain your results for the Extraction of Water Soluble Dyes keeping your experiment in mind. Relate your results to the theory relevant to the experiment.',
          fr: "Expliquez vos résultats pour L'extraction de colorants solubles dans l'eau en gardant à l'esprit votre expérience. Reliez vos résultats à la théorie pertinente pour l'expérience.",
        },
        {
          en: 'Explain your results for The Salting Out Effect keeping your experiment in mind. Relate your results to the theory relevant to the experiment.',
          fr: "Expliquez vos résultats pour L'effet de la salaison en gardant à l'esprit votre expérience. Reliez vos résultats à la théorie pertinente pour l'expérience.",
        },
        {
          en: 'Explain your results for Separating a Mixture with Reactive Extraction keeping your experiment in mind. Relate your results to the theory relevant to the experiment. Your discussion for part C should include an analysis of your TLC Plates',
          fr: "Expliquez vos résultats pour la Séparation d'un mélange à l'aide d'une extraction fondée sur la réactivité en gardant à l'esprit votre expérience. Reliez vos résultats à la théorie pertinente pour l'expérience. Votre discussion pour la Partie C doit inclure une analyze de vos plaques CCM.",
        },
      ],
    };
  },
  methods: {
    getQuestionText(question: any): string {
      return this.inputs.language === 'en' ? question.en : question.fr;
    },
  },
});
